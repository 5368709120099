<template>
  <div class="behavior_clamped_container">
    <v-tooltip top v-if="showIcon">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          aut-clamp-icon
          v-bind="attrs"
          v-on="on"
          class="behavior_more_display"
          @click.stop="toggleMore"
          >{{ clampIcon }}</v-icon
        >
      </template>
      {{ toggleLabel }}
    </v-tooltip>
    <div class="behavior_html_wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { isPlainObject } from "lodash";
const debug = require("debug")("atman.components.clamped_container"); // eslint-disable-line

const labels = ["See more", "See less"];
export default {
  name: "ClampedContainer",
  mounted() {
    this.toggleMore();
  },
  data() {
    return {
      toggleLabel: labels[0],
    };
  },
  computed: {
    clampIcon() {
      const defaultIcon = "mdi-dots-horizontal";
      let result = isPlainObject(this.clamp) ? this.clamp.icon : defaultIcon;
      result = result || defaultIcon;
      return result;
    },
    showIcon() {
      const result = this.clamp?.show_icon;
      if (typeof result != "boolean") {
        return true;
      }
      return result;
    },
  },
  props: {
    clamp: {},
  },
  methods: {
    toggleMore() {
      // IF this is not display mode, abort
      // IF the container is not found, abort
      const homeDiv = this.$el;
      if (!homeDiv) {
        console.error(`Couldn't find ref to apply clamped styles`);
        return;
      }

      // Get the more toggle button
      const moreToggle = homeDiv.querySelector(".behavior_more_display");

      // IF this is not clamped mode, hide the toggle and abort
      if (!this.clamp) {
        moreToggle.classList.add("hidden");
        return;
      }
      // If this toggle should show all the content, do that and abort.
      if (homeDiv.style["max-height"]) {
        homeDiv.style["max-height"] = "";
        this.toggleLabel = labels[1];
        return;
      }
      this.toggleLabel = labels[0];

      // If this toggle should clamp, add the clamped height to the outer container
      let sizeAsString =
        typeof this.clamp == "string" ? this.clamp : this.clamp.size;
      let size = sizeAsString * 1;
      if (isNaN(size)) {
        size = sizeAsString;
      } else {
        sizeAsString = `${size}px`;
      }
      debug(`sizeAsString`, sizeAsString);
      homeDiv.style["max-height"] = sizeAsString;

      debug(`max-height`, homeDiv.style["max-height"]);

      const htmlWrapper = homeDiv.querySelector(".behavior_html_wrapper");
      debug(htmlWrapper.clientHeight, homeDiv.clientHeight);
      // If the htmlwrapper is lesser than the clamped height and toggle exists, hide the toggle more button
      if (moreToggle != null) {
        if (htmlWrapper.clientHeight <= homeDiv.clientHeight) {
          moreToggle.classList.add("hidden");
        } else {
          moreToggle.classList.remove("hidden");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light {
  .behavior_more_display {
    background-color: white;
  }
}
.theme--dark {
  .behavior_more_display {
    background-color: #1e1e1e;
  }
}
.behavior_clamped_container {
  width: 100%;
  overflow: hidden;
  position: relative;
  .behavior_more_display {
    position: absolute;
    padding: 4px;
    bottom: -8px;
    right: 0px;
    padding: 5px;
    margin: 0px;
    &:focus::after {
      opacity: 0 !important;
    }
  }
}
</style>
